
import { SiteTypes, SiteStation } from "@/store/modules/site/site.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const siteX = namespace("Site");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddSite: () => import("@/components/site/AddSite.vue"),
    EditSite: () => import("@/components/site/EditSite.vue"),
    DeleteSite: () => import("@/components/site/DeleteSite.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class SitesView extends Vue {
  @siteX.Mutation(SiteTypes.SET_ADD_SITE_DIALOG)
  public setAddSite!: (addSite: boolean) => void;

  @siteX.Mutation(SiteTypes.SET_UPDATE_SITE_DIALOG)
  public setUpdateSite!: (updateSite: boolean) => void;

  @siteX.Mutation(SiteTypes.SET_DELETE_SITE_DIALOG)
  public setDeleteSite!: (deleteSite: boolean) => void;

  @siteX.Mutation(SiteTypes.SET_TICKET_SITE_DATA)
  public setSiteStationData!: (ticketSite: SiteStation) => void;

  @siteX.Action(SiteTypes.LOAD_SITES)
  public getSiteStations!: (pagination: Pagination) => void;

  @siteX.State(SiteTypes.SITES)
  public siteStations!: SiteStation[];

  @siteX.State(SiteTypes.LOADING_SITES_STATE)
  public loadingSites!: boolean;

  @siteX.State("addSiteRef")
  public addSiteRef!: number;

  openAddSiteDialog(): void {
    this.setAddSite(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "REGION",
      value: "region",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteSite(item: SiteStation): void {
    this.setDeleteSite(true);
    this.setSiteStationData(item);
  }

  editSite(item: SiteStation): void {
    this.setUpdateSite(true);
    this.setSiteStationData(item);
  }

  mounted(): void {
    this.getSiteStations({ page: 1, limit: 10 });
  }
}
